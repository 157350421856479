<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="원료사용량"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :gridHeightAuto="param.isFullScreen"
        :isExcelDown="false"
        :editable="editable"
        selection="multiple"
        rowKey="envAirDailyResultMaterialId"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="!param.isFullScreen && editable" label="추가" icon="add" @btnClicked="addRow" />
            <c-btn v-if="!param.isFullScreen && editable" label="삭제" icon="remove" @btnClicked="deleteRow" />
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-air-report-05',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envAirDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      unitItems: [],
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.air.daily.material.list.url;
      this.saveUrl = transactionConfig.env.air.daily.material.save.url;
      this.deleteUrl = transactionConfig.env.air.daily.material.delete.url;
      this.getColums();
    },
    getColums() {
      this.$http.url = selectConfig.env.air.mst.material.list.url;
      this.$http.type = 'GET';
      this.$http.param = {plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, item => {
          this.unitItems.push(
            {
              code: item.code,
              unitCd: item.airMaterialUnitName,
            },
          )
        });
        this.grid.columns = [
          {
            name: 'envAirMstMaterialName',
            field: 'envAirMstMaterialName',
            label: '원료명',
            align: 'center',
            sortable: false,
            comboItems: _result.data,
          },
          {
            name: 'materialVolume',
            field: 'materialVolume',
            label: '사용량',
            type: 'number',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'airMaterialUnitName',
            field: 'airMaterialUnitName',
            label: '단위',
            align: 'center',
            style: 'width: 150px',
            sortable: false,
          },
        ]
        this.getList();
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envAirDailyResultId: this.param.envAirDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addRow() {
      this.popupOptions.title = '대기 검사항목 검색';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/env/air/mst/airMasterMaterialPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePop;
    },
    closePop(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { envAirMstMaterialId: item.envAirMstMaterialId }) === -1) {
            this.grid.data.splice(0, 0, {
              envAirDailyResultId: this.param.envAirDailyResultId,  // 대기일지 일련번호
              envAirDailyResultMaterialId: uid(),  // 대기일지 원료사용량 일련번호
              envAirMstMaterialId: item.envAirMstMaterialId,  // 대기원료 일련번호
              envAirMstMaterialName: item.envAirMstMaterialName,
              materialVolume: '',  // 원료사용량
              remark: '',  // 비고
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId
            })
          }
        })
      }
    },
    deleteRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '저장되었습니다.', 
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    tableDataChange(props) {
      if (props.row['envAirMstMaterialId'] !== null) {
        let filterData = this.$_.filter(this.unitItems, item => {
          return item['code'] === props.row['envAirMstMaterialId']
        })
        this.$set(props.row, 'airMaterialUnitName', filterData[0].unitCd);
      }
    },
  }
};
</script>
